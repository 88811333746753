export const CONNECT_BANK_START = 'Bank Connect Start';
export const CONNECT_BANK_SUCCESS = 'Bank Connect Success';
export const CONNECT_BANK_FAILED = 'Bank Connect Failed';
export const CONNECT_BANK_EXIT = 'Bank Connect Exit';

// export const DISCONNECT_BANK_START = 'Bank Disconnect Start';
// export const DISCONNECT_BANK_SUCCESS = 'Bank Disconnect Success';
// export const DISCONNECT_BANK_FAILED = 'Bank Disconnect Failed';
//
// export const UPDATE_BANK_START = 'Bank Update Start';
// export const UPDATE_BANK_SUCCESS = 'Bank Update Success';
// export const UPDATE_BANK_FAILED = 'Bank Update Failed';
//
// export const PLAID_TOKEN_CREATE_START = 'Plaid Token Start';
// export const PLAID_TOKEN_CREATE_SUCCESS = 'Plaid Token Success';
// export const PLAID_TOKEN_CREATE_FAILED = 'Plaid Token Failed';
//
// export const DISCONNECT_BANK_DIALOG = 'BankDisconnect Dialog Open';
// export const DISCONNECT_BANK_CONFIRMED = 'BankDisconnect Dialog Confirm';
// export const CANCEL_DISCONNECT_BANK = 'BankDisconnect Dialog Cancel';
//
// export const PLAID_EVENT = 'Plaid Event';
